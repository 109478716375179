import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import newLogo from '../../../assets/images/frontend/header-logo.png'
import oldLogo from '../../../assets/images/frontend/logo-old.jpg'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/styles.css'

const AboutUs = () => {
    return (
        <>
            <InnerBanner pageName='About us'/>
            <section className="about-us section">
                <div className="container w-70">
                    <div className="row">
                        <h2 className="mb-4 lh-base titleFont title">International Pythian Council & Delphic India
                            Trust </h2>
                        <div className="col-md-12 mb-3 mb-md-5 text-justify">
                            <p className="f-semibold f-18 color-light-gray text-justify">Welcome to the International
                                Pythian Council
                                (IPC) and Delphic India Trust (DIT)!
                            </p>
                            <p>Mr. Bijender Goel, the founder of the Modern Pythian Games, started these non-profit
                                organizations in
                                New Delhi, India. His goal is to revive the ancient Pythian Games as the Modern Pythian
                                Games,
                                helping artists and players worldwide. He wants to make the Pythian Games the world’s
                                first Cultural
                                Games, focusing on promoting arts, culture, traditional games, and professional leagues
                                in various
                                sports.</p>
                            <p>The Modern Pythian Games are a unique way to connect artists and players from all over
                                the world. We
                                use digital, virtual, and global systems, working with member nations and international
                                organizations. Our vision is to give people a chance to showcase their talents, unite
                                nations
                                through arts and culture, and promote peace through cultural diplomacy. We also aim to
                                boost tourism
                                and the global economy by organizing events that celebrate arts, culture, and
                                traditional games.
                            </p>
                            <p>Our mission is to build a sense of community and belonging, promoting cultural exchange,
                                mutual
                                respect,
                                and understanding across borders. Join us as we celebrate the unity of humanity through
                                art,
                                culture,
                                and sports, and work together to create a world where these elements foster peace and
                                understanding everywhere.</p>
                        </div>
                    </div>
                    <div className="img-container">
                        <div className="row no-gutters justify-content-center mb-3">
                            <div className="trusties" style={{width: '250px', margin: '0 20px', padding: '10px'}}>
                                <figure>
                                    <img alt='new logo' className='w-100' src={newLogo}/>
                                </figure>
                            </div>
                            <div className="trusties" style={{width: '250px', margin: '0 20px', padding: '10px'}}>
                                <figure>
                                    <img alt='old logo' className='w-100' src={oldLogo}/>
                                </figure>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}
export default AboutUs