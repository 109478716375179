import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import InnerBanner from '../../Components/InnerBanner/InnerBanner';
import eventData from '../../../data/ourCouncil.json'
import getCouncilData from "../../Apis/ourCouncil";
import {storagePath} from "../../../assets/utils/soragePath";

const PartnerDetails = () => {
    const {slug} = useParams();
    const [data, setData] = useState({});

    const fetchCouncilData = async () => {
        try {
            const response = await getCouncilData(slug);
            console.log(response)
            if (response.status === 1) {
                setData(response.data[0].list[0])
            }
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        fetchCouncilData();
    }, [slug]);

    return (<>
        <InnerBanner pageName={"Partner Information"}/>
        <section className="cultural section">
            <div className="container">
                <div className="row g-4 mb-4">
                    <div className="col-md-4 col-lg-3 col-12">
                        <div className="">
                            <img src={storagePath + data.imageurl} className="w-100" alt={'Partner Image'}/>
                        </div>
                    </div>
                    <div className="col-md-8 col-lg-9 col-12">
                        <div className="">
                            <div className="">
                                <h2 className="mt-12">{data.firstname + " " + data.lastname}</h2>
                                <p><b>{data.desgination}</b></p>
                                {data.country && <p><b>Country: {data.country}</b></p>}
                                <p>{data.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
};

export default PartnerDetails;
