import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import 'bootstrap/dist/css/bootstrap.min.css';
import './ModernPythianGames.css'
import '../../../assets/css/styles.css'

import img1 from '../../../assets/images/frontend/img1.png'
import img2 from '../../../assets/images/frontend/img2.png'
import img3 from '../../../assets/images/frontend/img3.png'
import img4 from '../../../assets/images/frontend/img4.png'

const ModernPythianGames = () => {
    return (<>
        <InnerBanner pageName='Modern Pythian Games'/>
        <section className="Olympic-about section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <div className="row">
                            <div className="col mb-4"><img className="w-100"
                                                           src={img1}/></div>
                            <div className="col mb-4"><img className="w-100"
                                                           src={img2}/></div>
                        </div>
                        <div className="row">
                            <div className="col "><img className="w-100"
                                                       src={img3}/></div>
                            <div className="col"><img className="w-100"
                                                      src={img4}/></div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 Olympic-text text-justify">

                        <p>Modern Pythian Games, envisioned by Bijender Goel, aim to revive the traditions of the
                            ancient
                            Pythian Games in the modern world. These games strive to reach every artist and player
                            from across
                            the globe and ensure their participation through digital technology and global
                            networks.</p>
                        <p>Modern Pythian Games are the only worldwide platform for artists and players where they
                            have the
                            opportunity to showcase their talents to the world, representing their countries in the
                            fields of
                            arts, cultures, and traditional games either individually or in groups. The modern
                            Pythian Games
                            cover a wide range of categories, including Musical Arts, performing arts, visual arts,
                            Social Arts,
                            Language Arts, Ecological Arts, Digital Arts, Martial Arts, traditional games, e-sports,
                            adventure
                            games, water sports, and more. This inclusive program ensures more opportunities for
                            diverse talents
                            worldwide.</p>
                        <p>The International Pythian Council shall ensure the hosting of the <b>PYTHIAN GAMES,
                            JUNIOR PYTHIAN
                            GAMES, and PARA PYTHIAN GAMES</b> every four years. These games shall be held in those
                            countries
                            that bid the highest for them. The smooth conduct of various online and offline
                            activities and
                            programs, such as quizzes, awards, festivals, conclaves, conferences, fairs,
                            exhibitions, expos, and
                            professional leagues, shall be the focused agenda of the Pythian Games. Establishing
                            Pythian Clubs
                            in educational institutions to nurture talent from a young age and promote global
                            educational
                            exchange programs shall also be prioritized.</p>
                    </div>
                </div>
            </div>
        </section>
        <div className="pb-5">
            <div className="container">

                <h4 className="ancient-text f-500"> Vision 2050 for the Modern Pythian GamesVision 2050 for the
                    Modern Pythian Games
                </h4>
                <p>To achieve the ambitious vision of the Modern Pythian Games by 2050, a comprehensive strategy is
                    proposed
                    focusing on several key pillars:</p>
                <div className="row text-justify">
                    <div className="col-md-12">
                        <div className="vision-list">
                            <span>1</span>
                            <h4>Expansion of Games Categories</h4>
                            <p> To ensure inclusivity and diversity, we will expand the categories of the Pythian
                                Games to
                                encompass a wide range of artistic and traditional disciplines. This includes music,
                                performing
                                arts, visual arts, social and traditional arts, language and literary arts,
                                architecture and
                                ecology, robotics and digital arts, martial arts, entertainment games, adventure
                                games, and
                                traditional games. Each category will have its own set of competitions, festivals,
                                and
                                activities.</p>
                        </div>
                    </div>
                    <div className="col-md-12">

                        <div className="vision-list">
                            <span>2</span>
                            <h4>Global Bidding Process</h4>
                            <p>To encourage participation and international collaboration, any national or state
                                council
                                will be able to bid for the Pythian Games with the support of their respective
                                governments.
                                This will promote healthy competition and ensure that the Games are hosted in
                                different
                                parts of the world, fostering cultural exchange and understanding.</p>
                        </div>
                    </div>
                    <div className="col-md-12">

                        <div className="vision-list">
                            <span>3</span>
                            <h4>Online Portal</h4>
                            <p>The Modern Pythian Games' online platform will become a happening spot for artists
                                and
                                players from around the world. It'll offer chances to learn, work together, get
                                noticed, and
                                grow. Our goal is to make a user-friendly website that works well on all devices,
                                like
                                phones, tablets, and computers. We'll also organize online contests, challenges, and
                                events
                                to highlight and reward talent. This will make sure that the platform stays useful
                                for
                                creative folks, making a positive impact and encouraging cultural exchange globally,
                                even
                                beyond 2050.</p>
                        </div>
                    </div>
                    <div className="col-md-12">

                        <div className="vision-list">
                            <span>4</span>
                            <h4>Global Peace Awards</h4>
                            <p>Drawing inspiration from the ancient Delphi Peace Accord, the establishment of
                                prestigious awards across all sports and arts categories to honor excellence and
                                fostering values of sportsmanship and creativity, these awards serve as a testament
                                to the shared pursuit of peace and cooperation among nations. Through this
                                initiative, we aim to inspire individuals and groups to reach new heights of
                                achievement while promoting cultural exchange and mutual respect on a global
                                scale. </p>
                        </div>
                    </div>
                    <div className="col-md-12">

                        <div className="vision-list">
                            <span>5</span>
                            <h4>Establishment of Federations</h4>
                            <p> The creation of International Arts and Traditional Games Federations, along with
                                various National Pythian Councils globally, will be a primary agenda. These
                                federations will work towards standardizing rules and regulations, promoting the
                                Games globally, and providing support to artisans and players.</p>
                        </div>
                    </div>
                    <div className="col-md-12">

                        <div className="vision-list">
                            <span>6</span>
                            <h4>Alignment with Global Movements</h4>
                            <p> We will align with global movements supporting artisans and traditional games
                                worldwide. This will amplify our efforts in promoting the cultural and economic
                                significance of the Pythian Games.</p>
                        </div>
                    </div>
                    <div className="col-md-12">

                        <div className="vision-list">
                            <span>7</span>
                            <h4>Economic Impact</h4>
                            <p> Recognizing the potential economic benefits of hosting the Pythian Games, we will
                                focus on driving investments, promoting tourism and hospitality, strategic
                                marketing, facility development, and employment generation. We aim to create a
                                sustainable economic model that benefits host countries and regions while leaving a
                                lasting positive impact on the global economy.</p>
                        </div>
                    </div>
                    <div className="col-md-12">

                        <div className="vision-list">
                            <span>8</span>
                            <h4>Long-term Infrastructure Investment</h4>
                            <p> Hosting the Pythian Games will serve as a catalyst for long-term infrastructure
                                investment, contributing to the growth story of host countries. We will leverage the
                                event to attract international investors and develop robust infrastructure that
                                benefits local communities even beyond the Games.</p>
                        </div>
                    </div>
                    <div className="col-md-12">

                        <div className="vision-list">
                            <span>9</span>
                            <h4>Tourism and Hospitality</h4>
                            <p>The Pythian Games will draw a diverse crowd of international visitors, boosting
                                tourism and fostering business opportunities. We will focus on promoting local
                                cuisine, culture, and attractions, positioning host cities and regions as attractive
                                destinations for tourists throughout the year.</p>
                        </div>
                    </div>
                    <div className="col-md-12">

                        <div className="vision-list">
                            <span>10</span>
                            <h4> Strategic Marketing</h4>
                            <p>We will implement strategic marketing initiatives to elevate the profile of host
                                cities and regions on the global stage. This will include targeted campaigns to
                                promote tourism, business opportunities, and cultural exchange, leaving a lasting
                                impression on global audiences.</p>
                        </div>
                    </div>
                    <div className="col-md-12">

                        <div className="vision-list">
                            <span>11</span>
                            <h4>Facilities and Employment</h4>
                            <p> To ensure inclusivity and diversity, we will expand the categories of the Pythian
                                Games to
                                encompass a wide range of artistic and traditional disciplines. This includes music,
                                performing arts, visual arts, social and traditional arts, language and literary
                                arts,
                                architecture and ecology, robotics and digital arts, martial arts, entertainment
                                games,
                                adventure games, and traditional games. Each category will have its own set of
                                competitions,
                                festivals, and activities.</p>
                        </div>
                    </div>
                    <div className="col-md-12">

                        <div className="vision-list">
                            <span>12</span>
                            <h4>Monitoring and Evaluation:</h4>
                            <p> Throughout the implementation of our strategy, we will closely monitor and evaluate
                                our progress, making necessary adjustments to ensure that we remain on track to
                                achieve our long-term goals. This will involve collaboration with stakeholders,
                                regular assessments of economic impact, and transparent reporting on outcomes.</p>
                        </div>
                    </div>
                </div>
                <p className="text-justify">By implementing this strategy, we are confident that the Modern Pythian
                    Games will continue to grow and thrive, becoming a beacon of unity, diversity, and excellence on
                    the global stage by 2050 and beyond.</p>
            </div>

        </div>
    </>)
}
export default ModernPythianGames